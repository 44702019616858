import * as React from 'react'
import {CSSProperties} from "react";

import type { LinkViewModel } from '../../view-models/LinkViewModel'
import type { GtagEvent } from '../../utils/gtag'
import { trackEvent } from '../../utils/gtag'

const BLANK = '_blank'
type Props = LinkViewModel & {
  children?: React.ReactNode
  onClick?: (e: Event) => void
  eventMeta?: GtagEvent
  className?: string
  styles?: CSSProperties
}

class Anchor extends React.PureComponent<Props> {
  static displayName = 'Anchor'

  static isExternal(href: string) {
    if (!href) return false

    return !/^[#|/]/.test(href) && !href.includes(location.hostname)
  }
  //TODO: any
  handleClick: any = (event: Event) => {
    const { href, onClick } = this.props
    let eventMeta: GtagEvent | null | undefined = this.props.eventMeta

    if (href) {
      if (!eventMeta && Anchor.isExternal(href)) {
        // Track as an outbound link
        let eventLabel = 'Outbound Link'
        if (href.indexOf('mailto:') === 0) eventLabel = 'Email Link'
        if (href.indexOf('tel:') === 0) eventLabel = 'Phone Link'
        eventMeta = {
          event: 'cta',
          eventData: href,
          eventLabel,
        }
      }

      if (eventMeta && !eventMeta.eventData) {
        // Set the label to href if not defined
        eventMeta = { ...eventMeta, eventData: href }
      }
    }

    if (eventMeta) {
      trackEvent(eventMeta)
    }

    if (onClick) {
      onClick(event)
    }
  }

  render() {
    const { label, children, href, target, title, active, eventMeta, className, styles, ...rest } = this.props

    return (
      <a
        href={href}
        target={target}
        title={title !== label || children ? title : undefined}
        rel={target === BLANK ? 'noopener noreferrer' : undefined}
        onClick={this.handleClick}
        style={styles}
        {...rest}
        className={className}
      >
        {children || label}
      </a>
    )
  }
}

export default Anchor