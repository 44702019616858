import * as React from 'react'

import type { CtaViewModel } from '../../view-models/CtaViewModel'
import Button from '../Button/Button'

type Props = CtaViewModel & {
  onClick?: (...args: Array<any>) => any
  className?: string
  eventMeta?: {
    event: string
    eventData?: string
  }
  noUnderline?: boolean
  small?: boolean
  type?: "button" | "submit" | "reset" | undefined
  flat?: boolean
  darkBg?: boolean
  withArrow?: boolean
  disabled?: boolean
  noLink?: boolean,
}

function CtaButton({ className, noLink, ...rest }: Props) {
    if (noLink) {
      return <Button className={className} {...rest} noLink />
    }
    return <Button className={className} {...rest} {...rest.link} />
}

CtaButton.displayName = 'CtaButton'
CtaButton.defaultProps = {}
export default CtaButton