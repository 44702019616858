import cx from 'classnames'
import * as React from 'react'

import type { ImageViewModel } from '../../view-models/ImageViewModel'
import Image from '../Image/Image'
import styles from './avatar-style.css'

type Props = ImageViewModel & {
  size: 'small' | 'large' | 'responsive' | 'xsmall'
}

function Avatar(props: Props) {
  const { size, ...rest } = props

  if (!props.src) {
    console.warn('Please supply Avatar src')

    return null
  }

  return (

    <div className={cx(styles.avatar, styles[size])}>
      <Image {...rest} />
    </div>
  )
}

Avatar.displayName = 'Avatar'
Avatar.defaultProps = {
  size: 'small',
}
export default Avatar