/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import Modal from 'react-modal'

import Youtube from '../../components/Youtube/Youtube'
import CloseIcon from '../../icons/CloseIcon'
import BaseButton from '../BaseButton/BaseButton'
import styles from './youtubeModal-style.css'

type Props = {
  src: string
  isOpen: boolean
  onRequestClose: (...args: Array<any>) => any
  autoPlay: boolean
}
type State = {}

class YoutubeModal extends React.Component<Props, State> {
  static displayName = 'YoutubeModal'
  static defaultProps = {
    autoPlay: true,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      Modal.setAppElement(document.documentElement)
    }
  }

  render() {
    const { src, isOpen = false, autoPlay, onRequestClose, ...rest } = this.props
    if (!src) return null

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={true}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.close}>
          <BaseButton onClick={onRequestClose} className={styles.btn}>
            <CloseIcon width="15px" />
          </BaseButton>
        </div>
        <Youtube src={src} autoPlay={autoPlay} {...rest} />
      </Modal>
    )
  }
}

export default YoutubeModal