import cx from 'classnames'
import * as React from 'react'

import type { CSSDisplayTypes } from '../../view-models/enums/CSSDisplayTypes'
import type { IconTypes } from '../../view-models/enums/IconTypes'
import styles from './icon-styles.css'

function getIcon(name: string) {
  if (!name) return null

  const icon = require(`../../icons/${name}Icon.tsx`)

  if (icon) return icon.default
}

type Props = {
  iconName?: IconTypes
  iconSrc?: string
  width?: string
  height?: string
  className?: string
  display?: CSSDisplayTypes
}

function Icon({ iconName, width, height, iconSrc, className, display }: Props) {
  if (!iconName && !iconSrc) {
    console.warn('Please supply an iconName or icon source')

    return null
  }

  if (typeof iconSrc === 'string') {
    const viewBox = iconSrc.match(/\b(0 0 \d* \d*)/g)
    // const viewBox = iconSrc.match(/viewBox="\b(0 0 \d* \d*)"/g)
    const path = iconSrc.match(/<path\b([\s\S]*?)\/>/g)

    if (Array.isArray(viewBox) && Array.isArray(path)) {
      return (
        <svg
          viewBox={viewBox[0]}
          style={{
            display,
            width,
            height,
          }}
          dangerouslySetInnerHTML={{
            //TODO: Check if it is ok to ignore this here!
            // @ts-ignore
            __html: path,
          }}
          className={cx(styles.icon, className)}
        />
      )
    } else {
      console.warn(
        `Please supply an icon that meets the correct criterias. Icon was provided: ${iconSrc}`,
      )
    }
  }

  const Icon = iconName && getIcon(iconName)
  if (Icon) return <Icon width={width} height={height} className={className} />

  return null
}

Icon.displayName = 'Icon'
Icon.defaultProps = {
  display: 'inline-block',
}
export default Icon