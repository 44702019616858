import React from 'react'
type Props = {
  width?: string
  height?: string
}

const InfoIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M13.562 22.562v-5.625h-.78a.75.75 0 0 1-.548-.234.75.75 0 0 1-.234-.547v-1.875a.75.75 0 0 1 .234-.547.75.75 0 0 1 .547-.234h4.375a.75.75 0 0 1 .547.234.75.75 0 0 1 .234.547v8.281h.782a.75.75 0 0 1 .547.235.75.75 0 0 1 .234.547v1.875a.75.75 0 0 1-.234.547.75.75 0 0 1-.547.234H12.78a.75.75 0 0 1-.547-.234.75.75 0 0 1-.234-.547v-1.875a.75.75 0 0 1 .234-.547.75.75 0 0 1 .547-.235h.781zM15.75 6c.781 0 1.445.273 1.992.82.547.547.82 1.211.82 1.992 0 .782-.273 1.446-.82 1.993-.547.547-1.21.82-1.992.82a2.712 2.712 0 0 1-1.992-.82 2.712 2.712 0 0 1-.82-1.993c0-.78.273-1.445.82-1.992A2.712 2.712 0 0 1 15.75 6z"
    />
  </svg>
)

InfoIcon.defaultProps = {
  width: '32',
  height: '32',
}
export default InfoIcon