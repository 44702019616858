import React from 'react'
type Props = {
  width?: string
  height?: string
}

const ArrowDownIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M16.156 19.113l-4.047-4.02a.327.327 0 0 1-.109-.232.38.38 0 0 1 .082-.232l.547-.547a.4.4 0 0 1 .246-.082c.091 0 .164.027.219.082l3.281 3.254 3.281-3.254a.296.296 0 0 1 .219-.082.4.4 0 0 1 .246.082l.547.547a.38.38 0 0 1 .082.232.38.38 0 0 1-.082.233l-4.047 4.02a.4.4 0 0 1-.246.081.4.4 0 0 1-.246-.082h.027z"
    />
  </svg>
)

ArrowDownIcon.defaultProps = {
  width: '32',
  height: '32',
}
export default ArrowDownIcon