import cx from 'classnames'
import React, { PureComponent } from 'react'
import Measure from 'react-measure'

import type { ImageViewModel } from '../../view-models/ImageViewModel'
import type { ImageSizes } from '../../utils/image-utils'
import { aspectRatios } from '../../utils/image-utils'
import styles from './image-style.css'
import ImgElement from './ImgElement'
type Props = ImageViewModel & {
  style?: Record<string, any>
  className?: string
  //TODO: any
  onResize?: (bounds: any) => void
  sizes?: ImageSizes

  /** @internal, use the default aspect ratios for desktop and mobile  */
  autoAspectRatio?: boolean
  renderSpinner?: () => any
  defaultAspect?: number
}
type State = {
  animatedIn: boolean
  ready: boolean
  elementWidth: number
  elementHeight: number
}
/**
 * Image handles fetching the correct Imgix image, and rendering it onto the page.
 * By default it will load an image that fills the width of it's container, while keeping the original image aspect ratio
 */

class Image extends PureComponent<Props, State> {
  static displayName = 'Image'
  static defaultProps = {}

  static generateAspectRatio(width: number = 1, height: number = 1, maxAspectRatio: number = 2) {
    return Math.min(maxAspectRatio === 0 ? 2 : maxAspectRatio, height / width)
  }

  static generateImgixProps(props: Props) {
    return {
      fit: props.contain ? 'clip' : 'crop',
      crop: !props.contain ? (props.focalPoint ? 'focalpoint' : props.crop) : undefined,
      auto: ['compress', 'format'],
      'fp-x': props.focalPoint ? props.focalPoint.x : undefined,
      'fp-y': props.focalPoint ? props.focalPoint.y : undefined,
      ...props.imgixParams,
    }
  }

  static generateSource(
    src: string,
    width: number,
    height: number,
    imgixProps?: Record<string, any>,
  ) {
    if (width > 0 && height > 0) {
      return src
    }

    return null
  }

  static generateBlurredSource(
    src: string,
    width: number = 1,
    height: number = 1,
    imgixProps: Record<string, any> = {},
  ) {
    return src
  }

  state = {
    ready: false,
    animatedIn: false,
    elementWidth: 0,
    elementHeight: 0,
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  timeout: NodeJS.Timeout | null = null
  handleLoad = () => {
    if (this.props.fadeIn) {
      this.setState({
        ready: true,
      })
      this.timeout = setTimeout(() => {
        this.timeout = null
        this.setState({
          animatedIn: true,
        })
      }, 1000)
    } else {
      this.setState({
        ready: true,
        animatedIn: true,
      })
    }
  }
  handleTransitionEnd = () => {
    if (this.state.ready) {
      this.setState({
        animatedIn: true,
      })
    }
  }
  handleError = () => {
    this.setState({
      ready: true,
      animatedIn: true,
    })
  }
  handleMeasure = (contentRect) => {
    this.setState({
      elementWidth: Math.ceil(contentRect.bounds.width),
      elementHeight: Math.ceil(contentRect.bounds.height),
    })
    if (this.props.onResize) this.props.onResize(contentRect.bounds)
  }

  render() {
    const {
      src,
      fadeIn,
      disableBlur,
      maxAspectRatio,
      fill,
      contain,
      alignX,
      alignY,
      width,
      height,
      alt,
      caption,
      className,
      style,
      srcSet,
      sizes,
      autoAspectRatio,
      defaultAspect,
      hasBorder,
      extension,
    } = this.props
    const { ready, animatedIn, elementWidth, elementHeight } = this.state
    const imgixProps = Image.generateImgixProps(this.props)
    const currentSrc = Image.generateSource(src, elementWidth, elementHeight, imgixProps)
    const aspect: number | undefined = !fill
      ? defaultAspect
        ? defaultAspect
        : Image.generateAspectRatio(width, height, maxAspectRatio)
      : undefined

    const inner =
      extension === 'svg' || extension === 'gif' || extension === 'apng' ? ( // Don't let Sitecore resize svg, gif or apng - no need to resize svg, and gif and apng will be stuck on first frame
        <img
          src={src}
          className={cx(styles.img, hasBorder && styles.border)}
          alt={alt || undefined}
          onLoad={elementWidth && !ready ? this.handleLoad : undefined}
          role={!alt ? 'presentation' : undefined}
        />
      ) : (
        <React.Fragment>
          {!disableBlur && fadeIn && !animatedIn ? (
            <ImgElement
              src={Image.generateBlurredSource(src, width, height, imgixProps)}
              srcSet={srcSet}
              sizes={sizes}
              maxAspectRatio={aspect}
              contain={contain}
              fill={fill}
              className={styles.blurred}
              alignX={alignX}
              alignY={alignY}
              caption={caption}
              aria-hidden
            />
          ) : null}
          {currentSrc || (!fadeIn && !disableBlur) ? (
            <ImgElement
              src={currentSrc || Image.generateBlurredSource(src, width, height, imgixProps)}
              srcSet={srcSet}
              sizes={sizes}
              onLoad={elementWidth && !ready ? this.handleLoad : undefined}
              //TODO: The props commented out below don't exist in `ImgElement`
              // onError={elementWidth && !ready ? this.handleError : undefined}
              // onTransitionEnd={fadeIn ? this.handleTransitionEnd : undefined}
              className={cx(
                currentSrc ? styles.img : styles.blurred,
                fadeIn && !animatedIn
                  ? {
                      [styles.show]: ready,
                      [styles.hide]: !ready,
                    }
                  : null,
                hasBorder && styles.border,
              )}
              contain={contain}
              fill={fill}
              alignX={alignX}
              alignY={alignY}
              alt={alt}
              hasBorder={hasBorder}
            />
          ) : null}
        </React.Fragment>
      )

    return (
      <Measure bounds onResize={this.handleMeasure}>
        {({ measureRef }) => (
          <figure className={styles.figure}>
            <div
              ref={measureRef}
              className={cx(styles.container, className, {
                [styles.fill]: fill,
              })}
              style={
                autoAspectRatio
                  ? {
                      paddingBottom: `${
                        elementWidth === 0 && defaultAspect
                          ? defaultAspect * 100
                          : elementWidth < 768
                          ? aspectRatios.mobile * 100
                          : aspectRatios.desktop * 100
                      }%`,
                    }
                  : aspect
                  ? {
                      paddingBottom: `${aspect * 100}%`,
                      ...style,
                    }
                  : style
              }
            >
              {inner}
              {/*{disableBlur && !ready && renderSpinner ? renderSpinner() : null}*/}
            </div>
            {caption && <figcaption className={styles.figcaption}>{caption}</figcaption>}
          </figure>
        )}
      </Measure>
    )
  }
}

export default Image