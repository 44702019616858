import React from 'react'
type Props = {
  width?: string
  height?: string
}

const PinterestIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32">
    <path d="M17.23 19.56c-1 0-1.92-.53-2.24-1.14l-.68 2.52c-.4 1.45-1.57 2.9-1.66 3-.06.1-.2.07-.2-.04-.04-.18-.34-2 0-3.5l1.2-5s-.3-.6-.3-1.46c0-1.36.8-2.37 1.77-2.37.84 0 1.24.62 1.24 1.38 0 .84-.54 2.1-.82 3.26-.23 1 .5 1.8 1.45 1.8 1.75 0 2.93-2.24 2.93-4.9 0-2-1.36-3.5-3.83-3.5-2.8 0-4.52 2.07-4.52 4.4 0 .8.23 1.36.6 1.8.17.2.2.27.14.5l-.2.74c-.05.24-.24.32-.45.23-1.3-.52-1.9-1.93-1.9-3.5C9.8 11.12 12 8 16.37 8c3.5 0 5.83 2.54 5.83 5.26 0 3.6-2 6.3-4.97 6.3" />
  </svg>
)

PinterestIcon.defaultProps = {
  width: '32',
  height: '32',
}
export default PinterestIcon