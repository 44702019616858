import React from 'react'
type Props = {
  width?: string
  height?: string
}

const TwitterIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32">
    <path d="M22.36 12.74v.42c0 4.34-3.3 9.34-9.33 9.34A9.3 9.3 0 0 1 8 21.03c.26.03.52.04.78.04a6.58 6.58 0 0 0 4.08-1.4A3.3 3.3 0 0 1 9.8 17.4a3.3 3.3 0 0 0 1.48-.07 3.28 3.28 0 0 1-2.64-3.22v-.02c.45.25.95.4 1.5.4A3.28 3.28 0 0 1 9.1 10.1a9.32 9.32 0 0 0 6.78 3.43 3.28 3.28 0 0 1 5.6-3 6.57 6.57 0 0 0 2.08-.8 3.3 3.3 0 0 1-1.44 1.83 6.6 6.6 0 0 0 1.88-.52 6.68 6.68 0 0 1-1.64 1.7" />
  </svg>
)

TwitterIcon.defaultProps = {
  width: '32',
  height: '32',
}
export default TwitterIcon