import * as React from 'react'
import styles from './spinner-style.css'

function Spinner() {
  return (
    <div className={styles.spinner}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

Spinner.displayName = 'Spinner'
Spinner.defaultProps = {}
export default Spinner