import cx from 'classnames'
import * as React from 'react'

import type { VideoViewModel } from '../../view-models/VideoViewModel'
import { trackEvent } from '../../utils/gtag'
import type { ImageSizes } from '../../utils/image-utils'
import { aspectRatios } from '../../utils/image-utils'
import BaseButton from '../BaseButton/BaseButton'
import Image from '../Image/Image'
import MediaQuery from '../MediaQuery/MediaQuery'
import PlayButton from '../PlayButton/PlayButton'
import Youtube from '../Youtube/Youtube'
import YoutubeModal from '../YoutubeModal/YoutubeModal'
import styles from './videoPlayer-style.css'

type Props = VideoViewModel
type State = {
  isOpen: boolean
  isReady: boolean
  loadVideo: boolean
  eventIsSaved: boolean
}
const sizes: ImageSizes = {
  maxWidth: '100vw',
}

class VideoPlayer extends React.Component<Props, State> {
  static displayName = 'VideoPlayer'
  static defaultProps = {}
  state = {
    isOpen: false,
    isReady: false,
    loadVideo: false,
    eventIsSaved: false,
  }
  handleRequestClose = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    this.setState({
      isOpen: false,
    })
  }
  handleOpen = () => {
    this.setState({
      isOpen: true,
    })

    if (!this.state.eventIsSaved) {
      trackEvent({
        event: 'video-open',
      })
      this.setState({
        eventIsSaved: true,
      })
    }
  }
  handleYoutubeReady = () => {
    this.setState({
      isReady: true,
    })
  }
  handleLoadVideo = () => {
    this.setState({
      loadVideo: true,
    })
  }

  render() {
    const { videoId, placeholder, videoTitle, aspectRatio } = this.props
    const { loadVideo } = this.state

    if (!videoId) {
      console.warn('Please provide a video id')

      return null
    }

    return (
      <MediaQuery defaultMatches={true} query={'(min-width: 769px)'}>
        {(matches) => (
          <BaseButton
            className={styles.button}
            onClick={matches ? this.handleOpen : this.handleLoadVideo}
          >
            {({ hovering }) => (
              <div
                className={cx(styles.video, hovering && styles.hovering)}
                style={{
                  paddingBottom: aspectRatio
                    ? `calc(${aspectRatio * 100}%)`
                    : matches
                    ? `calc(${aspectRatios.desktop} * 100%)`
                    : `calc(${aspectRatios.mobile} * 100%)`,
                }}
              >
                <Image {...placeholder} src={placeholder?.src ?? ''} fill sizes={sizes} />
                <div className={styles.actions}>
                  <div className={styles.centerWrap}>
                    <PlayButton className={styles.playbtn} />
                    {videoTitle && <span className={styles.title}>{videoTitle}</span>}
                  </div>
                </div>
                {matches ? (
                  <YoutubeModal
                    src={videoId}
                    isOpen={this.state.isOpen}
                    onRequestClose={this.handleRequestClose}
                  />
                ) : (
                  loadVideo && (
                    <Youtube
                      src={videoId}
                      onReady={this.handleYoutubeReady}
                      autoPlay={this.state.isReady}
                    />
                  )
                )}
              </div>
            )}
          </BaseButton>
        )}
      </MediaQuery>
    )
  }
}

export default VideoPlayer