import cx from 'classnames'
import * as React from 'react'

import {IconTypes} from "../../view-models/enums/IconTypes";

import type { ImageViewModel } from '../../view-models/ImageViewModel'
import Avatar from '../Avatar/Avatar'
import CircleIcon from '../CircleIcon/CircleIcon'
import Heading from '../Heading/Heading'
import styles from './headingWithImg-style.css'

type Props = {
  title: string
  icon?: string
  iconName?: IconTypes
  image?: ImageViewModel
  size?: 'small' | 'big'
}

const HeadingWithImg = ({ title, icon, iconName, image, size = 'big' }: Props) => {
  return (
    <div
      className={cx(
        (image || icon) && styles.headingWithMedia,
        icon && styles.headingWithIcon,
        styles.flex,
        !icon && !image && styles.headingNoImg,
      )}
    >
      {image ? (
        <Avatar {...image} />
      ) : icon ? (
        <CircleIcon className={styles.icon} icon={icon} iconName={iconName} size={size} />
      ) : null}
      {title && (
        <Heading className={styles.title} align="left" level={2} noMargin={!(!icon && !image)}>
          {title}
        </Heading>
      )}
    </div>
  )
}

HeadingWithImg.displayName = 'HeadingWithImg'
export default HeadingWithImg