import cx from 'classnames'
import * as React from 'react'

import type { CtaViewModel } from '../../view-models/CtaViewModel'
import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import ReactRichText from './ReactRichText'
import styles from './rte-style.css'

type Props = {
  source?: string
  cta?: CtaViewModel
  className?: string
  dividerHeadline?: string
}

const Rte = ({ source, cta, dividerHeadline, className }: Props) => {
  const eventData = cta && cta.link ? cta.link.label : ''

  return (
    <article className={cx(styles.rte, className)}>
      {dividerHeadline && (
        <Heading level={2} align="center" divider>
          {dividerHeadline}
        </Heading>
      )}
      {source && <ReactRichText source={source} />}
      {cta && (
        <Button
          version={cta.version}
          {...cta.link}
          icon={cta.icon ?? undefined}
          eventMeta={{
            event: 'cta',
            eventData,
          }}
        />
      )}
    </article>
  )
}

export default Rte