import React from 'react'

const CalenderIcon = () => (
  <svg width="22" height="21" viewBox="0 0 22 21">
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M.75 7.75v12h20v-12h-10zm16-6v2h-3v-2h-6v2h-3v-2h-4v6h20v-6z" />
      <path d="M4.75 2.75v-2h3v2m6 1h3v-3h-3z" />
    </g>
  </svg>
)

export default CalenderIcon