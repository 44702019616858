import * as React from 'react'
import * as ReactDOM from 'react-dom'

// import styled from 'styled-components'
import FocusTrap from '../FocusTrap/FocusTrap'
type Props = {
  onRequestClose?: () => void
  className?: string
  focusSelector?: string
  isOpen: boolean
  children: React.ReactNode
}
type State = {}
/**
 * The BaseModal creates a wrapper that handles opening and closing the modal while managing focus.
 **/

class BaseModal extends React.Component<Props, State> {
  static displayName = 'Modal'
  static defaultProps = {
    isOpen: false,
  }

  render() {
    const { children, focusSelector, className, isOpen, onRequestClose } = this.props
    const modal = (
      <FocusTrap active={isOpen} focusSelector={focusSelector} onRequestClose={onRequestClose}>
        {({ ref }) => (
          <div role="dialog" data-testid="modal" ref={ref} className={className}>
            {children}
          </div>
        )}
      </FocusTrap>
    )

    if (global.document && global.document.body) {
      return ReactDOM.createPortal(modal, global.document.body)
    }

    return modal
  }
}

export default BaseModal