import React from 'react'

import type { ImageViewModel } from '../../view-models/ImageViewModel'
import type { ImageSizes } from '../../utils/image-utils'
import { generateSizes } from '../../utils/image-utils'
import { objectFitSupport } from './helpers'

function getObjectPosition(alignX, alignY) {
  return alignX || alignY ? `${alignX || 'center'} ${alignY || 'center'}` : undefined
}

type Props = ImageViewModel & {
  className?: string
  contain?: boolean
  fill?: boolean
  onLoad?: (...args: Array<any>) => any
  sizes?: ImageSizes
  //TODO: Check if the two props below should really be added here.
  // They are passed down by `Image`
  // onError?: () => any
  // onTransitionEnd?: () => any
}

/**
 * Img Element with ObjectFit fallback in unsupported browsers (IE)
 */
function ImgElement({
  src,
  alt,
  contain,
  fill,
  alignX,
  alignY,
  onLoad,
  className,
  srcSet,
  sizes,
  hasBorder,
  ...props
}: Props) {
  if (process.env.NODE_ENV !== 'production' && !sizes) {
    // throw new Error(
    //   'Images needs "sizes" to define correct responsive image sizes',
    // )
  }

  /**
   * If this Image requires unsupported ObjectFit, fallback to a <div> with backgroundImage
   **/
  if ((alignX || alignY || contain || fill) && !objectFitSupport() && !hasBorder) {
    if (onLoad) {
      // Trigger the onLoad - could create a dummy image instead that triggers it after the image is actually loaded.
      onLoad()
    }

    return (
      <div
        className={className}
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: contain ? 'contain' : 'cover',
          backgroundPosition: fill ? 'center' : getObjectPosition(alignX, alignY),
        }}
      />
    )
  }

  return (
    <img
      sizes={sizes ? generateSizes(sizes.breakpoints, sizes.maxWidth) : generateSizes()}
      srcSet={srcSet || `${src} 1350w`}
      src={src}
      className={className}
      style={{
        objectPosition: getObjectPosition(alignX, alignY),
        objectFit: contain ? 'contain' : undefined,
      }}
      onLoad={onLoad}
      alt={alt || ''}
      role={!alt ? 'presentation' : undefined}
      {...props}
    />
  )
}

ImgElement.defaultProps = {
  contain: false,
}
export default ImgElement