import React from 'react'
type Props = {}

const BurgerIcon = (props: Props) => (
  <svg width="35" height="18" viewBox="0 0 35 18" {...props}>
    <g
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 1h29" />
      <path d="M1 9h29" />
      <path d="M5 17h29" />
    </g>
  </svg>
)

BurgerIcon.defaultProps = {}
export default BurgerIcon