import * as React from 'react'

import { times } from '../../icons'
import type { TitleAndTextViewModel } from '../../view-models/TitleAndTextViewModel'
import Heading from '../Heading/Heading'
import Icon from '../Icon/Icon'
import Paragraph from '../Paragraph/Paragraph'
import styles from './infoBox-style.css'

type Props = TitleAndTextViewModel & {
  onCloseModal?: (...args: Array<any>) => any
}
type State = {}

class InfoBox extends React.Component<Props, State> {
  static displayName = 'InfoBox'
  static defaultProps = {}

  render() {
    const { title, text, onCloseModal } = this.props

    return (
      <div onClick={(e) => e.stopPropagation()} className={styles.infoBox}>
        <button onClick={onCloseModal} className={styles.close}>
          <Icon iconSrc={times} width="16px" height="16px" />
        </button>
        {title && <Heading level={2}>{title}</Heading>}
        {text && (
          <Paragraph marginBottom={'none'} renderNewlines={true}>
            {text}
          </Paragraph>
        )}
      </div>
    )
  }
}

export default InfoBox