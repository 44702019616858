import cx from 'classnames'
import * as React from 'react'

import type { LinkViewModel } from '../../view-models/LinkViewModel'
import type { GtagEvent } from '../../utils/gtag'
import type { ButtonState } from '../BaseButton/BaseButton'
import BaseButton from '../BaseButton/BaseButton'
import Icon from '../Icon/Icon'
import styles from './button-style.css'
import { ButtonTypes } from "@/view-models/ButtonTypes";

type Props = LinkViewModel & {
  children?: React.ReactNode
  secondary?: boolean
  small?: boolean
  disabled?: boolean
  inactive?: boolean
  onClick?: (...args: Array<any>) => any
  fill?: boolean
  outline?: boolean
  wide?: boolean
  icon?: string
  eventMeta?: GtagEvent
  withArrow?: boolean
  flat?: boolean
  className?: string
  version?: ButtonTypes | number
  iconOnly?: boolean
  darkBg?: boolean
  type?: "button" | "submit" | "reset" | undefined
  noUnderline?: boolean
  noLink?: boolean
}

type State = {
  hovering: boolean
}

class Button extends React.Component<Props, State> {
  static displayName = 'Button'
  static defaultProps = {}

  state = {
    hovering: false,
  }

  handleInteraction = (state: ButtonState) => {
    this.setState({
      hovering: state.hover || state.focus || state.touch,
    } as State)
  }

  render() {
    const {
      secondary,
      children,
      href,
      onClick,
      disabled,
      inactive,
      label,
      icon,
      small,
      wide,
      fill,
      flat,
      outline,
      withArrow,
      className,
      version,
      iconOnly,
      darkBg,
      noLink,
      ...rest
    } = this.props
    const { hovering } = this.state

    const enumConvertedVersion = version === 2 ? "secondary" : version === 0 ? "link" : "primary"

    return (
      <BaseButton
        href={noLink ? undefined : href}
        noLink={noLink}
        onClick={!inactive ? onClick : undefined}
        onInteraction={!disabled ? this.handleInteraction : undefined}
        disabled={disabled}
        className={cx(
          className,
          darkBg && styles.darkBg,
          secondary ? styles.secondary : styles.primary,
          enumConvertedVersion ? styles[enumConvertedVersion] : styles.primary,
          small && styles.small,
          wide && styles.wide,
          fill && styles.fill,
          !!icon && styles.hasIcon,
          outline && styles.outline,
          flat && styles.flat,
          hovering && !inactive ? styles.hovering : null,
        )}
        {...rest}
      >
        {icon ? <Icon iconSrc={icon} /> : null}
        {!iconOnly ? (
          <span className={styles.inner}>{children || label}</span>
        ) : null}
        {withArrow || enumConvertedVersion === 'link' ? (
          <div className={styles.arrowHolder}>
            <Icon iconName="ArrowRight" className={styles.arrow} />
            <Icon iconName="ArrowRight" className={styles.arrow} />
          </div>
        ) : null}
      </BaseButton>
    )
  }
}

export default Button
