import React from 'react'
type Props = {
  width?: string
  height?: string
}

const LockIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.719 6.562c.39 0 .722.137.996.41.273.274.41.606.41.997v5.625c0 .39-.137.722-.41.996-.274.273-.606.41-.996.41H1.406c-.39 0-.722-.137-.996-.41a1.356 1.356 0 0 1-.41-.996V7.969c0-.39.137-.723.41-.996.274-.274.606-.41.996-.41h.703v-2.11c0-.8.2-1.543.601-2.226C3.11 1.543 3.652 1 4.336.6 5.02.2 5.762 0 6.562 0c.801 0 1.543.2 2.227.6.684.401 1.226.943 1.626 1.627.4.683.6 1.425.6 2.226v2.11h.704zm-3.985 4.922v-1.406c0-.332-.112-.61-.337-.835a1.134 1.134 0 0 0-.835-.337c-.332 0-.61.113-.834.337a1.134 1.134 0 0 0-.337.835v1.406c0 .332.112.61.337.835.224.225.502.337.834.337.333 0 .61-.112.835-.337.225-.224.337-.503.337-.835zm.938-4.922V4.453c0-.586-.205-1.084-.615-1.494a2.034 2.034 0 0 0-1.495-.615c-.585 0-1.083.205-1.494.615-.41.41-.615.908-.615 1.494v2.11h4.219z"
    />
  </svg>
)

LockIcon.defaultProps = {
  width: '16',
  height: '16',
}
export default LockIcon