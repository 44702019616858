import cx from 'classnames'
import * as React from 'react'

import { truncateString } from '../../utils/dom'
import styles from './heading-style.css'

type Props = {
  /** The visual size of the Heading */
  level: 1 | 2 | 3 | 4 | 5 | 6

  /** The actual tag that should be rendered in the browser */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: React.ReactNode
  noMargin?: boolean
  extraMargin?: boolean
  truncateLength?: number
  className?: string
  align?: 'left' | 'right' | 'center'
  divider?: boolean
}

class Heading extends React.PureComponent<Props> {
  static displayName = 'Heading'
  static defaultProps = {
    level: 1,
  }

  renderChildren = () => {
    const { children, truncateLength } = this.props

    return React.Children.map(children, (child, index) =>
      index > 0
        ? [<br key="br" />, child]
        : truncateLength
        ? truncateString(child, truncateLength)
        : child,
    )
  }

  render() {
    const { level, tag, noMargin, extraMargin, className, align, divider, ...rest } = this.props
    const Tag = tag || `h${level}`

    return (
      <Tag
        className={cx(
          styles[`h${level}`],
          noMargin && styles.noMargin,
          extraMargin && styles.extraMargin,
          align && styles[align],
          divider && styles.divider,
          className,
        )}
        {...rest}
      >
        {divider ? <div>{this.renderChildren()}</div> : this.renderChildren()}
      </Tag>
    )
  }
}

export default Heading