import React from 'react'
type Props = {
  width?: string
  height?: string
  className?: string
}

const ArrowRightIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 32 32"
    className={props.className}
  >
    <path
      fill="currentColor"
      d="M15.96 10.082a.4.4 0 0 1 .247-.082c.091 0 .164.027.219.082l5.715 5.742a.26.26 0 0 1 .109.219.26.26 0 0 1-.11.219l-5.714 5.742a.296.296 0 0 1-.219.082.4.4 0 0 1-.246-.082l-.547-.547a.296.296 0 0 1-.082-.219.4.4 0 0 1 .082-.246l4.238-4.238h-9.324a.316.316 0 0 1-.232-.096.316.316 0 0 1-.096-.232v-.766c0-.091.032-.168.096-.232a.316.316 0 0 1 .232-.096h9.324l-4.238-4.238a.4.4 0 0 1-.082-.246c0-.091.027-.164.082-.22l.547-.546z"
    />
  </svg>
)

ArrowRightIcon.defaultProps = {}
export default ArrowRightIcon