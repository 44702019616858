import cx from 'classnames'
import parseHtml, { DomElement, domToReact } from 'html-react-parser'
import React from 'react'
import parse from 'style-to-js'

import Heading from '../../components/Heading/Heading'
import List from '../../components/List/List'
import Paragraph from '../../components/Paragraph/Paragraph'
import styles from './rte-style.css'
import Table, { headerRowClass } from './Table/Table'

type Props = {
  className?: string
  source?: string
}

const isHtml = (html: string) => /<\/?[a-z][\s\S]*>/i.test(html)

/**
 * Use the `<RichText>` component to convert Rich Text HTML into React components from our UI library.
 * */
export const setMinWidth = (style: string): Record<string, any> => {
  const styleObj = parse(style)

  if (styleObj.width) {
    styleObj.minWidth = styleObj.width
  }

  return styleObj
}
//@ts-expect-error
const RichTextHtml = React.memo(({ source, style }: Props) => {
  if (!source) return null
  const trimmedHtml = source // Normalize whitespace
    .replace(/[\f\n\r\t\v ]{2,}/g, ' ')
    .split('\n')
    .join('')
    .trim()

  function replaceHTML({ attribs, name, children }: DomElement) {
    if (!attribs || !name || !children) return false

    switch (name) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        const level: 1 | 2 | 3 | 4 | 5 | 6 = name.split('')[1]

        return (
          <Heading tag={name} level={level} {...attribs}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </Heading>
        )

      case 'ul':
      case 'ol':
        return (
          <List ordered={name === 'ol'}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </List>
        )

      case 'li':
        return (
          <li {...attribs}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </li>
        )

      case 'img':
        if (attribs.hasOwnProperty('style')) delete attribs['style']

        return <img {...attribs} alt={attribs.alt} />

      case 'p':
        return (
          <Paragraph noBreaks>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </Paragraph>
        )

      case 'table':
        return (
          <Table attribs={attribs}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </Table>
        )

      case 'thead':
        return (
          <div role="rowgroup" className={cx(styles.rowContainer, headerRowClass)}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </div>
        )

      case 'tbody':
        return (
          <div role="rowgroup" className={styles.rowContainer}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </div>
        )

      case 'tr':
        return (
          <div role="row" className={styles.row}>
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </div>
        )

      case 'th':
        return (
          <div
            {...attribs}
            role="cell"
            className={styles.thead}
            style={setMinWidth(attribs.style) || undefined}
          >
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </div>
        )

      case 'td':
        return (
          <div
            {...attribs}
            role="cell"
            className={cx(styles.td)}
            style={setMinWidth(attribs.style) || undefined}
          >
            {domToReact(children, {
              replace: replaceHTML,
            })}
          </div>
        )

      default:
        return false
    }
  }

  return (
    <div>
      {parseHtml(trimmedHtml, {
        replace: replaceHTML,
      })}
    </div>
  )
})

const ReactRichText = (props: Props) => {
  const { source } = props
  if (!source) return null

  if (!isHtml(source)) {
    return <Paragraph>{parseHtml(source)}</Paragraph>
  }

  return <RichTextHtml {...props} />
}

export default ReactRichText