import React from 'react'

const BackIcon = () => (
  <svg width="13" height="24">
    <path
      d="M1 12l11 11M1 12L12 1"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default BackIcon