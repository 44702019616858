import useElementSize from '@charlietango/use-element-size'
import cx from 'classnames'
import React from 'react'

import { cviArrowLeft, cviArrowRight } from '../../../icons'
import Button from '../../Button/Button'
import Icon from '../../Icon/Icon'
import styles from '../rte-style.css'

type Props = {
  children: React.ReactNode
  attribs: Record<string, any>
}
export const headerRowClass = 'headerRow'

const Table = ({ children, attribs }: Props) => {
  const [tableRef, table] = useElementSize()
  const [wrapperRef, wrapper] = useElementSize()
  //TODO: any
  const [refState, setRefState] = React.useState<any | null>(null)
  //TODO: any
  const [navigationList, setNavigationList] = React.useState<any[]>([])
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const [lastScrollableItem, setLastScrollableItem] = React.useState<boolean>(
    false,
  )
  //TODO: any
  const timeoutRef: React.MutableRefObject<any | null> = React.useRef(null)
  const NEXT = 'next'
  const PREV = 'prev'
  const setRefCallback = React.useCallback(node => {
    if (node) {
      tableRef(node)
      setRefState(node)
    }
  }, [])
  const scrollHandler = React.useCallback(
    e => {
      if (!refState) return
      const wrapperPos = Math.ceil(refState.getBoundingClientRect().left)
      const parentPos = Math.ceil(
        refState.parentNode.getBoundingClientRect().left,
      )
      const outOfBounds = Math.ceil(
        refState.getBoundingClientRect().width +
          15 -
          refState.parentNode.getBoundingClientRect().width,
      )
      //TODO: any
      const posArray = navigationList.map((item: any) => item.offsetLeft)

      if (timeoutRef && timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        setLastScrollableItem(false)

        if (wrapperPos - 15 + outOfBounds === parentPos) {
          setLastScrollableItem(true)
        }

        posArray.some((item, i) => {
          if (item === wrapperPos - 15 + outOfBounds && !lastScrollableItem) {
            setCurrentIndex(i + 1)
          }

          if (-wrapperPos >= item && item < posArray[i + 1]) {
            setCurrentIndex(i + 1)
          }

          if (posArray[0] === wrapperPos) {
            setCurrentIndex(0)
          }

          return null
        })
      }, 100)
    },
    [refState, navigationList],
  )
  React.useEffect(() => {
    if (refState) {
      refState.parentNode.addEventListener('scroll', scrollHandler, {
        passive: true,
      })

      return () =>
        refState.parentNode.removeEventListener('scroll', scrollHandler)
    }
  }, [scrollHandler, refState])
  React.useEffect(() => {
    if (refState) {
      const topCells = Array.from(refState.children[0].children[0].children)
      setNavigationList(topCells)
    }
  }, [refState])

  const clickHandler = (e, type) => {
    let nextTarget
    const increaseIndex = currentIndex + 1
    const decreaseIndex = currentIndex - 1

    if (refState) {
      switch (type) {
        case NEXT:
          if (lastScrollableItem) return
          nextTarget = navigationList[increaseIndex].offsetLeft
          setCurrentIndex(increaseIndex)
          break

        case PREV:
          if (currentIndex === 0) return
          nextTarget = navigationList[decreaseIndex].offsetLeft
          setCurrentIndex(decreaseIndex)
          break

        default:
          throw new Error('Unknown type')
      }

      refState.parentNode.scrollLeft = nextTarget
    }
  }

  const needsButtons = table.width > wrapper.width

  return (
    <>
      <div ref={wrapperRef} className={styles.tableWrapper}>
        <div
          ref={setRefCallback}
          {...attribs}
          role="table"
          className={styles.table}
        >
          {children}
        </div>
      </div>
      {needsButtons && (
        <div className={styles.navigationButtons}>
          <Button
            onClick={e => clickHandler(e, PREV)}
            className={cx(currentIndex === 0 ? styles.hidden : '')}
            aria-label="forrige"
          >
            <Icon iconSrc={cviArrowLeft} width={'14px'} height={'14px'} />
          </Button>
          <Button
            onClick={e => clickHandler(e, NEXT)}
            className={cx(lastScrollableItem ? styles.hidden : '')}
            aria-label="næste"
          >
            <Icon iconSrc={cviArrowRight} width={'14px'} height={'14px'} />
          </Button>
        </div>
      )}
    </>
  )
}

Table.displayName = 'Table'
Table.defaultProps = {}
export default Table
