import cx from 'classnames'
import * as React from 'react'

import { truncateString } from '../../utils/dom'
import styles from './paragraph-style.css'

type Props = {
  children?: React.ReactNode
  className?: string
  style?: Record<string, any>
  marginBottom?: 'none' | 'small' | 'normal' | 'large' | 'xlarge'

  /** Size corresponding to the body styles in the styleguide */
  size?: 'normal' | 'medium' | 'large' | 'xsmall' | 'small'
  weight?: 'regular' | 'light' | 'bold'

  /** Don't add breaks when rendering an Array. */
  noBreaks?: boolean
  tag?: string

  /** Give a max characther-count before it truncates */
  truncateLength?: number
  renderNewlines?: boolean
}

function Paragraph({
  children,
  className,
  style,
  marginBottom,
  size,
  noBreaks,
  tag,
  truncateLength,
  weight,
  renderNewlines,
}: Props) {
  const P: string | undefined = tag

  return (
    //TODO: Is it ok to ignore it here?
    // @ts-ignore
    <P
      className={cx(
        styles.p,
        styles[`body_${size}`],
        styles[`marginBottom_${marginBottom}`],
        styles[`weight_${weight}`],
        renderNewlines ? styles.renderNewlines : undefined,
        className,
      )}
      style={style}
    >
      {!noBreaks && React.Children.count(children) > 1
        ? React.Children.map(children, (child, index) =>
            index > 0 ? [<br key="br" />, child] : child,
          )
        : truncateLength && typeof children === 'string'
        ? React.Children.map(children, child =>
            truncateString(child, truncateLength),
          )
        : children}
    </P>
  )
}

Paragraph.displayName = 'Paragraph'
Paragraph.defaultProps = {
  size: 'normal',
  tag: 'p',
  marginBottom: 'normal',
  weight: 'regular',
}
export default Paragraph
