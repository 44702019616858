import React from 'react'

type Props = {
    width?: string,
    height?: string,
}

const CloseIcon = (props: Props) => (
  <svg width="30" height="30" viewBox="0 0 30 30" {...props}>
    <g transform="rotate(-45, 15, 15)">
      <rect width="2" height="30" x="14" rx="1" />
      <rect width="2" height="30" x="14" rx="1" transform="rotate(-90 15 15)" />
    </g>
  </svg>
)

CloseIcon.defaultProps = {}
export default CloseIcon