import cx from 'classnames'
import * as React from 'react'
import {CSSProperties} from "react";

import { angleDown } from '../../icons'
import type { ShowFromListViewModel } from '../../view-models/ShowFromListViewModel'
import uniqueId from '../../utils/unique-id'
import Icon from '../Icon/Icon'
import styles from './select-style.css'

type Props = {
  items: Array<ShowFromListViewModel>
  placeholder?: string
  onChange: (...args: Array<any>) => any
  id: string
  label?: string
  defaultValue?: string
  style?: CSSProperties
}

const handleChange = (
  event: Record<string, any>,
  onChange: (...args: Array<any>) => any,
  items: Array<ShowFromListViewModel>,
) => {
  let target = event.target.value
  let selected = items.find((item) => item.keypair.key === target)
  onChange(selected)
}

function Select({ items, placeholder, onChange, id, label, defaultValue, style }: Props) {
  return (
    <form id={uniqueId()}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={cx(style ? style : styles.select)}>
        <select
          onChange={(e) => handleChange(e, onChange, items)}
          tabIndex={!label ? -2 : 0}
          id={id}
          value={defaultValue}
        >
          <option value={''} disabled>
            {placeholder}
          </option>
          {items &&
            items.map(({ keypair }) => (
              <option key={keypair.value} value={keypair.key}>
                {keypair.value}
              </option>
            ))}
        </select>
        <div className={styles.iconWrapper}>
          <Icon width={'12px'} height={'18px'} iconSrc={angleDown} />
        </div>
      </div>
    </form>
  )
}

export default Select