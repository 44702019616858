import cx from 'classnames'
import * as React from 'react'

import styles from './dividerComp-style.css'
type Props = {
  skinny?: boolean

  /** When DividerComp is used as a module, this should be set to true always. Set this when exposing this component as a module. */
  fullWidth?: boolean

  /** Force the divider to be shown on desktop */
  showOnDesktop?: boolean
  marginTop?: 'normal' | 'none'
  marginBot?: 'normal' | 'none'

  /** For mobile navigation */
  noSideMargin?: boolean
}

function DividerComp({
  skinny,
  fullWidth = true,
  showOnDesktop,
  marginBot = 'normal',
  marginTop = 'normal',
  noSideMargin,
}: Props) {
  return (
    <div
      className={cx(
        styles.divider,
        styles[`marginTop_${marginTop}`],
        styles[`marginBot_${marginBot}`],
        skinny ? styles.skinny : styles.regular,
        fullWidth && styles.fullWidth,
        showOnDesktop && styles.showOnDesktop,
        noSideMargin && styles.noSideMargin,
      )}
    />
  )
}

DividerComp.displayName = 'DividerComp'
DividerComp.defaultProps = {
  marginTop: 'normal',
  marginBot: 'normal',
}
export default DividerComp