import React from 'react'
type Props = {
  width?: string
  height?: string
}

const UnlockIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.422.875c.8 0 1.543.2 2.226.6.684.401 1.226.943 1.626 1.627.4.683.601 1.425.601 2.226v2.344a.678.678 0 0 1-.205.498.678.678 0 0 1-.498.205h-.938a.678.678 0 0 1-.498-.205.678.678 0 0 1-.205-.498V5.328a2.01 2.01 0 0 0-.63-1.494 2.066 2.066 0 0 0-1.494-.615c-.576 0-1.07.21-1.48.63-.41.42-.615.923-.615 1.508v2.08h1.407c.39 0 .722.137.996.41.273.274.41.606.41.997v5.625c0 .39-.137.722-.41.996-.274.273-.606.41-.996.41H1.406c-.39 0-.722-.137-.996-.41a1.356 1.356 0 0 1-.41-.996V8.844c0-.39.137-.723.41-.996.274-.274.606-.41.996-.41H7.97v-2.08c0-.801.2-1.544.6-2.227a4.63 4.63 0 0 1 1.612-1.64 4.227 4.227 0 0 1 2.24-.616zM7.734 12.359v-1.406c0-.332-.112-.61-.337-.835a1.134 1.134 0 0 0-.835-.337c-.332 0-.61.113-.834.337a1.134 1.134 0 0 0-.337.835v1.406c0 .332.112.61.337.835.224.225.502.337.834.337.333 0 .61-.112.835-.337.225-.224.337-.503.337-.835z"
    />
  </svg>
)

UnlockIcon.defaultProps = {
  width: '16',
  height: '16',
}
export default UnlockIcon