import cx from 'classnames'
import * as React from 'react'
import {CSSProperties} from "react";

import { GtagEvent } from "@/utils/gtag";

import Anchor from '../../components/Anchor/Anchor'
import type { LinkViewModel } from '../../view-models/LinkViewModel'
import Icon from '../Icon/Icon'
import stylesCss from './link-style.css'

export const isDifferentSubdomain = (nextUrl?: string) => {
  if (!nextUrl) {
    return false
  }

  const matches = nextUrl.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i) // eslint-disable-line

  const nextSubdomain = matches && matches[1] // domain will be null if no match is found

  const currentSubdomain = global.location && global.location.hostname.split('.')[0]

  return nextSubdomain !== currentSubdomain
}
export function isExternalLink(href?: string) {
  if (!href) return false
  const isHttp = href.indexOf('http') === 0

  return (
    !/^[#|/]/.test(href) &&
    isHttp &&
    (isDifferentSubdomain(href) || (global.location && !href.includes(global.location.hostname)))
  )
}

export function isContactLink(href?: string) {
  if (!href) return false

  return href.startsWith('mailto:') || href.startsWith('tel:')
}

type Props = LinkViewModel & {
  children?: React.ReactNode
  className?: string
  secondary?: boolean
  noUnderline?: boolean
  hoverArrow?: boolean
  eventMeta?: GtagEvent
  styles?: CSSProperties
}
type State = {}

class Link extends React.Component<Props, State> {
  static displayName = 'Link'
  static defaultProps = {
    secondary: false,
    theme: 'dark',
  }

  render() {
    const { children, secondary, className, noUnderline, hoverArrow, styles, ...rest } = this.props

    return (
      <Anchor
        className={cx(
          !secondary ? stylesCss.primary : stylesCss.secondary,
          className,
          noUnderline && stylesCss.noUnderline,
          hoverArrow && stylesCss.hoverArrow,
        )}
        styles={styles}
        {...rest}
      >
        {children}
        {hoverArrow && (
          <div className={stylesCss.arrowHolder}>
            <Icon iconName="ArrowRight" className={stylesCss.arrow} />
            <Icon iconName="ArrowRight" className={stylesCss.arrow} />
          </div>
        )}
      </Anchor>
    )
  }
}

export default Link