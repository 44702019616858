import * as React from 'react'
import styles from './list-style.css'
type Props = {
  children?: React.ReactNode
  ordered?: boolean
}

function List({ children, ordered }: Props) {
  if (ordered) return <ol className={styles.ol}>{children}</ol>
  return <ul className={styles.ul}>{children}</ul>
}

List.displayName = 'List'
List.defaultProps = {}
export default List