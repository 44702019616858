import React from 'react'
type Props = {
  width?: string
  height?: string
}

const InstagramIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32">
    <g transform="translate(7 7)">
      <path d="M4.37 0h9.26A4.38 4.38 0 0 1 18 4.37v9.26A4.38 4.38 0 0 1 13.63 18H4.37A4.38 4.38 0 0 1 0 13.63V4.37A4.38 4.38 0 0 1 4.37 0zm0 1.54a2.8 2.8 0 0 0-2.83 2.83v9.26a2.8 2.8 0 0 0 2.83 2.83h9.26a2.8 2.8 0 0 0 2.83-2.83V4.37a2.8 2.8 0 0 0-2.83-2.83H4.37zm9.77 1.55a1.03 1.03 0 1 1 0 2.02 1.03 1.03 0 0 1 0-2.05zM9 4.6A4.38 4.38 0 0 1 13.37 9 4.38 4.38 0 0 1 9 13.37 4.38 4.38 0 0 1 4.63 9 4.38 4.38 0 0 1 9 4.63zm0 1.54A2.82 2.82 0 0 0 6.17 9 2.82 2.82 0 0 0 9 11.83 2.82 2.82 0 0 0 11.83 9 2.82 2.82 0 0 0 9 6.17z" />
    </g>
  </svg>
)

InstagramIcon.defaultProps = {
  width: '32',
  height: '32',
}
export default InstagramIcon