import React from 'react'
type Props = {
  width?: string
  height?: string
}

const FacebookIcon = (props: Props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 32 32">
    <path d="M20.16 10.66h-1.5c-1.2 0-1.42.56-1.42 1.38v1.82h2.82l-.37 2.84h-2.47V24H14.3v-7.3h-2.45v-2.84h2.45v-2.1C14.3 9.32 15.8 8 17.96 8c1.04 0 1.93.08 2.2.1v2.56z" />
  </svg>
)

FacebookIcon.defaultProps = {
  width: '32',
  height: '32',
}
export default FacebookIcon