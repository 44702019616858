const supports: { objectFit?: boolean } = {
  objectFit: undefined
}

/**
 * Detect if objectFit is supported on the client
 * @type {boolean}
 */
export function objectFitSupport(): boolean {
  if (!supports.objectFit) {
    supports.objectFit = global.document ? global.document.body.style.objectFit !== undefined : true
  }

  return supports.objectFit
}
export default {
  objectFitSupport,
}