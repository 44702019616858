import cx from 'classnames'
import * as React from 'react'

import {IconTypes} from "../../view-models/enums/IconTypes";

import Icon from '../Icon/Icon'
import styles from './circleIcon-style.css'
type Props = {
  icon?: string
  iconName?: IconTypes
  className?: string
  size?: 'small' | 'big'
}

function CircleIcon({ icon, iconName, className, size }: Props) {
  if (!icon) return null

  return (
    <div
      className={cx(
        styles.circleIcon,
        {
          [styles.circleIconResponsive]: !size,
        },
        {
          [styles.circleIconSmall]: size === 'small',
        },
        {
          [styles.circleIconBig]: size === 'big',
        },
        className,
      )}
    >
      {icon && <Icon iconSrc={icon} iconName={iconName} />}
    </div>
  )
}

CircleIcon.displayName = 'CircleIcon'
CircleIcon.defaultProps = {}
export default CircleIcon