import * as React from 'react'

import {
  markForFocusLater,
  returnFocus,
  setupScopedFocus,
  teardownScopedFocus,
} from './helpers/focusManager'
import scopeTab from './helpers/scopeTab'
import findTabbableDescendants from './helpers/tabbable'

type Props = {
  /** Is the trap active? */
  active: boolean
  children?:
    | ((arg0: { ref: (node: HTMLElement | null | undefined) => void }) => React.ReactNode)
    | React.ReactNode

  /** Element tag to use for the wrapping element when rendering a plain React.Node. Defaults to 'div'  */
  tag?: string

  /** Use this query selector to find a focus item when active. Otherwise it will select the first focusable element */
  focusSelector?: string

  /** Trigger this function when the focus is cleared by hitting Escape */
  onRequestClose?: () => void
}
/**
 * Traps focus to children when active.
 * Use this when rendering a modal to ensure focus the user tabs inside the relevant area
 * */

class FocusTrap extends React.Component<Props> {
  static displayName = 'FocusTrap'
  static defaultProps = {
    active: false,
  }

  componentDidMount() {
    const { active } = this.props

    if (active) {
      this.toggleOpenState(active)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.active !== this.props.active) {
      this.toggleOpenState(this.props.active)
    }
  }

  componentWillUnmount() {
    if (this.props.active) {
      this.toggleOpenState(false)
    }
  }

  node: HTMLElement | null | undefined = null

  toggleOpenState(active: boolean) {
    const { focusSelector } = this.props
    const node = this.node

    if (active) {
      /* Clear focus when opening the Navigation */
      document.addEventListener('keydown', this.handleKeyDown)

      if (node) {
        setupScopedFocus(node)
        markForFocusLater()
        let focusElement: HTMLElement | null = null

        if (focusSelector) {
          focusElement = node.querySelector(focusSelector)
        }

        if (!focusElement) {
          const tabbableChildren = findTabbableDescendants(node)

          if (tabbableChildren && tabbableChildren.length) {
            focusElement = tabbableChildren[0] as HTMLElement
          }
        }

        if (focusElement) {
          focusElement.focus()
        }
      }
    } else {
      /* Restore focus when closing */
      document.removeEventListener('keydown', this.handleKeyDown)
      returnFocus()
      teardownScopedFocus()
    }
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab' && this.node) {
      scopeTab(this.node, event)
    }

    if (event.key === 'Escape' && this.props.onRequestClose) {
      this.props.onRequestClose()
    }
  }
  handleNode = (node: HTMLElement | null | undefined) => {
    this.node = node
  }

  render() {
    if (typeof this.props.children === 'function') {
      // Act as a render prop, and allow the container to set the ref
      return this.props.children({
        ref: this.handleNode,
      })
    }

    const { tag, children, active, onRequestClose, focusSelector, ...rest } = this.props

    return React.createElement(
      tag || 'div',
      {
        ref: this.handleNode,
        ...rest,
      },
      children,
    )
  }
}

export default FocusTrap