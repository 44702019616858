import * as React from 'react'
import styles from './errorMessage-style.css'
import Heading from '../Heading/Heading'
import Rte from '../Rte/Rte'
import CtaButton from '../CtaButton/CtaButton'
import { ErrorMessageViewModel } from '@/view-models/ErrorMessageViewModel'

type Props = ErrorMessageViewModel

function ErrorMessage({ heading, message, cta }: Props) {
  return (
    <div className={styles.errorMessage}>
      {heading && (
        <Heading className={styles.heading} level={2} tag={'h3'}>
          {heading || 'Error'}
        </Heading>
      )}
      {message && (
        <Rte
          className={styles.errorMarkdown}
          source={message || 'Try refreshing the page'}
        />
      )}
      {cta && <CtaButton {...cta} className={styles.cta} />}
    </div>
  )
}

ErrorMessage.displayName = 'ErrorMessage'
ErrorMessage.defaultProps = {}
export default ErrorMessage

