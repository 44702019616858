import * as React from 'react'
import cx from 'classnames'
import styles from './playButton-style.css'
import PlayIcon from '../../icons/PlayIcon'
type Props = {
  className?: string
}

function PlayButton({ className }: Props) {
  return (
    <div className={cx(styles.playButton, className)}>
      <PlayIcon width="18px" />
    </div>
  )
}

PlayButton.displayName = 'PlayButton'
PlayButton.defaultProps = {}
export default PlayButton