import cx from 'classnames'
import * as React from 'react'

import Anchor from '../../components/Anchor/Anchor'
import type { LinkViewModel } from '../../view-models/LinkViewModel'
import type { GtagEvent } from '../../utils/gtag'
import { trackEvent } from '../../utils/gtag'
import styles from './baseButton-style.css'

export type ButtonState = {
  hover?: boolean
  touch?: boolean
  focus?: boolean
  active?: boolean

  /** Any of focus, touch or hover is true */
  hovering?: boolean
}

type Props = LinkViewModel & {
  /** Children can be a node, or a function that receives the current interaction state */
  children?: React.ReactNode | ((state: ButtonState) => React.ReactNode | null | undefined)
  className?: string
  onClick?: (e: Event) => void | any

  /** Always render as anchor tag, don't fall back to button */
  isAnchor?: boolean

  /** Get the current interaction state of the button */
  onInteraction?: (...args: Array<any>) => any
  disabled?: boolean
  eventMeta?: GtagEvent

  /** disable underline links on hover */
  noUnderline?: boolean
  type?: "button" | "submit" | "reset" | undefined
  noLink?: boolean
}
/**
 * Base Button handles creating either an <a> or <button> tag if element has an href
 *
 **/

class BaseButton extends React.Component<Props, ButtonState> {
  static displayName = 'BaseButton'
  static defaultProps = {}
  state = {
    hover: false,
    touch: false,
    focus: false,
    active: false,
    hovering: false,
  }

  handleEvent = (e: Event) => {
    const { onInteraction } = this.props
    const type = e.type
    this.setState(
      (state) => {
        /* Queue the state change, so we keep the correct event flow */
        const newState: ButtonState = { ...state }

        switch (type) {
          case 'mouseenter':
            if (!state.touch) {
              newState.hover = true
            }

            break

          case 'touchend':
          case 'touchcancel':
          case 'mouseleave':
            newState.hover = false
            newState.active = false
            newState.touch = false
            break

          case 'mousedown':
            newState.active = true
            break

          case 'mouseup':
            newState.active = false
            break

          case 'focus':
            newState.focus = true
            break

          case 'blur':
            newState.focus = false
            break

          case 'touchstart':
            newState.touch = true
            break

          default:
            return
        }

        newState.hovering = newState.touch || newState.focus || newState.hover

        return newState
      },
      onInteraction
        ? () => {
            onInteraction(this.state)
          }
        : undefined,
    )
  }
  //TODO: any
  handleClick: any = (e: Event) => {
    const { onClick, eventMeta } = this.props

    if (eventMeta) {
      trackEvent(eventMeta)
    }

    if (onClick) onClick(e)
  }

  render() {
    const {
      children,
      className,
      href,
      disabled,
      onInteraction,
      active,
      onClick,
      eventMeta,
      isAnchor,
      noUnderline,
      type='button',
      noLink=false,
      ...rest
    } = this.props
    const renderAsAnchor = isAnchor || (!!href && !disabled)
    const isOnlyText = noLink
    //TODO: any
    const ButtonComp: any = renderAsAnchor ? Anchor : 'button'
    const addEvents = !!onInteraction || typeof children === 'function'
    const shared = {
      className: cx(styles.reset, className, noUnderline && styles.noUnderline),
      disabled,
      onMouseEnter: addEvents ? this.handleEvent : undefined,
      onMouseLeave: addEvents ? this.handleEvent : undefined,
      onMouseDown: addEvents ? this.handleEvent : undefined,
      onMouseUp: addEvents ? this.handleEvent : undefined,
      onTouchStart: addEvents ? this.handleEvent : undefined,
      onTouchEnd: addEvents ? this.handleEvent : undefined,
      onTouchCancel: addEvents ? this.handleEvent : undefined,
      onFocus: addEvents ? this.handleEvent : undefined,
      onBlur: addEvents ? this.handleEvent : undefined,
      ...rest,
    }
    const inner = typeof children === 'function' ? children({ ...this.state }) : children

    if (isOnlyText) {
      return (
        <div
          className={cx(
            styles.reset,
            className,
            noUnderline && styles.noUnderline,
          )}
          tabIndex={-1}
        >
          {inner}
        </div>
      )
    }

    if (renderAsAnchor) {
      return (
        <Anchor eventMeta={eventMeta} href={href} {...shared}>
          {inner}
        </Anchor>
      )
    }

    return (
      <ButtonComp type={type} onClick={this.handleClick} {...shared}>
        {inner}
      </ButtonComp>
    )
  }
}

export default BaseButton
