import cx from 'classnames'
import * as React from 'react'
import ReactYoutube from 'react-youtube'

import { trackEvent } from '../../utils/gtag'
import Spinner from '../Spinner/Spinner'
import styles from './youtube-style.css'

export type YoutubeAPI = {
  playVideo: () => void
  getVideoData: () => {
    video_id: string
    title: string
    video_quality: string
    author: string
  }
  getDuration: () => number
  getCurrentTime: () => number
}
export type YoutubeEvent = {
  target: YoutubeAPI
  data?: -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6
}
type Props = {
  src: string
  autoPlay?: boolean
  onReady?: (...args: Array<any>) => any
  onPlay?: (...args: Array<any>) => any
  onEnd?: (...args: Array<any>) => any
  onStateChange?: (...args: Array<any>) => any
}
type State = {
  started: boolean
  title: string
  gaProgress: number
  isReady: boolean
  isLoading: boolean
  eventIsSent: boolean
}
const trackingBreakPoints = [90, 75, 50, 25, 10]
const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/
export function getYoutubeId(src: string): string {
  const [, videoId] = youtubeRegex.exec(src) || []

  return videoId || src
}

class Youtube extends React.PureComponent<Props, State> {
  static displayName = 'Youtube'
  static defaultProps = {}
  state = {
    started: false,
    title: '',
    gaProgress: 0,
    isReady: false,
    isLoading: true,
    eventIsSent: false,
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.gaProgress < this.state.gaProgress &&
      !this.state.eventIsSent
    ) {
      trackEvent({
        event: 'video-open',
      })

      //TODO: any
      this.setState<any>({
        eventIsSent: true,
      } as State)
      // Youtube.trackEvent(`Progress ${this.state.gaProgress}%`, this.state.title)
    }
  }

  componentWillUnmount() {
    this.stopProgress()
  }

  interval: any | null | undefined
  handleStateChange = (e: YoutubeEvent) => {
    if (e.data === 1 && !this.state.started) {
      const info = e.target.getVideoData()
      this.setState({
        started: true,
        isLoading: false,
        title: info.title,
      })
      // Youtube.trackEvent('Play', info.title)
      this.monitorProgress(e.target)
    } else if (e.data === 0 && this.state.started) {
      this.setState({
        started: false,
      })
      // Youtube.trackEvent('Complete', this.state.title)
      this.stopProgress()
    }

    if (this.props.onStateChange) {
      this.props.onStateChange(e)
    }
  }

  stopProgress() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  }

  monitorProgress(player: YoutubeAPI) {
    const duration = player.getDuration()
    this.stopProgress()
    if (!duration) return
    this.interval = setInterval(() => {
      // Progress in percentage
      const time = player.getCurrentTime()
      const progress = Math.round((time / duration) * 100)
      const trackPoint = trackingBreakPoints.find(val => val <= progress) || 0

      if (trackPoint >= this.state.gaProgress) {
        // If moved past last breakpoint, track it
        this.setState({
          gaProgress: trackPoint,
        })
      }
    }, 1000)
  }

  handleOnReady = () => {
    this.setState({
      isReady: true,
      isLoading: false,
    })
    if (this.props.onReady) this.props.onReady()
  }

  render() {
    const { src, autoPlay, onReady, ...rest } = this.props
    const { isLoading } = this.state
    if (!src) return null
    const videoId = getYoutubeId(src)

    return (
      <div>
        <ReactYoutube
          id={`yt-${videoId}`}
          className={cx(styles.iframe, this.state.started && styles.ready)}
          videoId={videoId}
          onStateChange={this.handleStateChange}
          onReady={this.handleOnReady}
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: autoPlay ? 1 : 0,
              //TODO(Props): According to the type, this prop can only be equal to undefined or 1
              // modestbranding: 0,
              color: 'white',
              showinfo: 0,
              rel: 0,
              controls: 1,
            },
          }}
          {...rest}
        />
        {isLoading && <Spinner />}
      </div>
    )
  }
}

export default Youtube
