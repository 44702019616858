import * as React from 'react' // https://github.com/akiran/react-slick/issues/1240

export default function useLockScroll() {
  //TODO: any
  const ref: React.RefObject<any> = React.createRef()
  // it would be good to useCallback
  React.useEffect(() => {
    const newRef = ref.current
    let firstClientX, clientX

    const preventTouch = e => {
      const minValue = 5 // threshold

      clientX = e.touches[0].clientX - firstClientX

      // Vertical scrolling does not work when you start swiping horizontally.
      if (Math.abs(clientX) > minValue) {
        e.preventDefault()
        e.returnValue = false

        return false
      }
    }

    const touchStart = e => {
      firstClientX = e.touches[0].clientX
    }

    const iOS =
      !!window.navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

    if (newRef && iOS) {
      newRef.addEventListener('touchstart', touchStart)
      newRef.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (newRef && iOS) {
        newRef.removeEventListener('touchstart', touchStart)
        newRef.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  })

  return ref
}
