import React from 'react'

const EmailIcon = () => (
  <svg width="22" height="15">
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M21.5 13a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 13V2A1.5 1.5 0 0 1 2 .5h18A1.5 1.5 0 0 1 21.5 2v11z" />
      <path d="M21 1L11 9 1 1" />
    </g>
  </svg>
)

export default EmailIcon