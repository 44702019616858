import React from 'react'
type Props = {}

const SmallCloseIcon = (props: Props) => (
  <svg width="14" height="14" {...props}>
    <g fill="currentColor" transform="rotate(-45 5.121 10.607)">
      <rect width="18" height="2" y="8" rx="1" />
      <rect width="18" height="2" y="8" rx="1" transform="rotate(-90 9 9)" />
    </g>
  </svg>
)

SmallCloseIcon.defaultProps = {}
export default SmallCloseIcon