import cx from 'classnames'
import * as React from 'react'

import { trackEvent } from '../../utils/gtag'
import scrollToElement from '../../utils/ScrollToElement'
import Heading from '../Heading/Heading'
import styles from './optionAccordions-style.css'

type Props = {
  items: Array<Record<string, any>>
  renderItems?: (...args: Array<any>) => any
  eventName: string
  className?: string
  multipleOpen?: boolean
}
type State = {
  currentIndex: number
  currentNode: any
  multipleOpenIndexes: Record<string, any>
}

class OptionAccordions extends React.Component<Props, State> {
  static displayName = 'OptionAccordions'
  static defaultProps = {}
  state = {
    currentIndex: -1,
    currentNode: null,
    multipleOpenIndexes: {},
  }

  /**
   * Use refresh if you're changing the inner content of the accordion.
   * This will fit the box according to its new size after content change.
   */
  refresh = (node: any, nextIndex: number, id: string) => {
    const nextSibling = node.current.nextSibling
    const currentScrollHeight = nextSibling.scrollHeight
    // Set height to zero so we can calculate the real scrollHeight without our own artificial height.
    // Height must only increase in size, because if it gets smaller, there's not going to be a transition.
    nextSibling.style.height = 0
    nextSibling.style.maxHeight = nextSibling.scrollHeight + 'px'
    nextSibling.style.height =
      (nextSibling.scrollHeight > currentScrollHeight
        ? nextSibling.scrollHeight
        : currentScrollHeight) + 'px'
  }
  handleOnClick = (node: any, nextIndex: number, id: string) => {
    const nextNode = node.current
    nextNode.nextSibling.style.maxHeight =
      nextNode.nextSibling.scrollHeight + 'px'
    nextNode.nextSibling.style.height = nextNode.nextSibling.scrollHeight + 'px'
    nextNode.nextSibling.style.visibility = 'visible'
    const { currentNode, currentIndex } = this.state as State

    if (currentNode) {
      currentNode.nextSibling.style.maxHeight = '0'
      setTimeout(() => {
        // Check added to prevent hiding content when double clicking
        if (currentIndex !== this.state.currentIndex) {
          currentNode.nextSibling.style.visibility = 'hidden'
        }
      }, 400)
    }

    if (currentIndex === nextIndex) {
      return this.setState({
        currentIndex: -1,
        currentNode: null,
      })
    } else {
      if (this.props.eventName === 'accordion') {
        const eventLabel = this.props.items[nextIndex].title || ''
        trackEvent({
          event: this.props.eventName,
          eventLabel,
        })
      }

      if (this.props.eventName === 'daekning') {
        const eventData = this.props.items[nextIndex].title
        trackEvent({
          event: this.props.eventName,
          eventData,
        })
      }
    }

    this.setState({
      currentIndex: nextIndex,
      currentNode: nextNode,
    })
    scrollToElement(id, 100)
  }
  handleMultipleOnClick = (node: any, nextIndex: number, id: string) => {
    const currentState = this.state.multipleOpenIndexes
    const nextNode = node.current

    if (nextNode.nextSibling.style.visibility !== 'visible') {
      nextNode.nextSibling.style.maxHeight =
        nextNode.nextSibling.scrollHeight + 'px'
      nextNode.nextSibling.style.height =
        nextNode.nextSibling.scrollHeight + 'px'
      nextNode.nextSibling.style.visibility = 'visible'
      currentState[nextIndex] = true
    } else if (nextNode.nextSibling.style.visibility === 'visible') {
      nextNode.nextSibling.style.visibility = 'hidden'
      nextNode.nextSibling.style.maxHeight = '0'
      currentState[nextIndex] = false
    }

    scrollToElement(id, 100)
    this.setState({
      multipleOpenIndexes: currentState,
    })
  }

  render() {
    const { items, renderItems, className, multipleOpen } = this.props
    if (!items) return null
    const { currentIndex, multipleOpenIndexes } = this.state

    return (
      <ul className={cx(styles.optionAccordions, className && className)}>
        {typeof renderItems === 'function' ? (
          renderItems(
            items,
            multipleOpen ? this.handleMultipleOnClick : this.handleOnClick,
            multipleOpen ? multipleOpenIndexes : currentIndex,
            this.refresh,
          )
        ) : (
          <Heading level={3}>
            Please provide renderItems() that returns slider items.
          </Heading>
        )}
      </ul>
    )
  }
}

export default OptionAccordions
